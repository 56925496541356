.headerAdd {
    background-color: #333;
    padding: 10px;
    text-align: center;
  }
  
  .headerAdd ul {
    list-style: none;
    padding: 0;
  }
  
  .headerAdd ul li {
    display: inline;
    margin-right: 20px;
  }
  
  .headerAdd a {
    text-decoration: none;
    color: #fff;
  }

  .headerList {
    background-color: #333;
    padding: 10px;
    text-align: center;
  }
  
  .headerList ul {
    list-style: none;
    padding: 0;
  }
  
  .headerList ul li {
    display: inline;
    margin-right: 20px;
  }
  
  .headerList a {
    text-decoration: none;
    color: #fff;
  }