body {
  font-family: Arial, sans-serif;
  background-color: #222;
  color: #fff;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
  padding: 20px;
  margin-bottom: 5vh;
}
