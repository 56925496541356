/* insertTodo-Stil */

.insertTodo {
    background-color: #333;
    padding: 20px;
    text-align: center;
    position: sticky; /* Änderung zur sticky-Position */
    bottom: 0;
    left: 50%;

    z-index: 100; /* Füge z-index hinzu, um es über anderen Inhalten zu halten */
  }
  
  .insertTodo h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .insertTodo form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .insertTodo label {
    margin: 5px 0;
  }
  
  .insertTodo input {
    padding: 5px;
    border: none;
    border-radius: 5px;
  }
  
  .insertTodo button {
    background-color: #0077ff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin-top: 10px;
    cursor: pointer;
  }
  
  .insertTodo button:hover {
    background-color: #0055cc;
  }
  
  