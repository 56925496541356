
.deleteTodo {
    background-color: #333;
    padding: 20px;
    text-align: center;
  }
  
  .deleteTodo h1 {
    font-size: 24px;
    margin-bottom: 10px;
    text-decoration: underline;
    padding: 10px;
  }

  .deleteTodo input {
    padding: 5px;
    border: none;
    border-radius: 5px;
  }

  .deleteTodo button {
    background-color: #da2323;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin-top: 10px;
    cursor: pointer;
  }
  
  .deleteTodo form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }