  .todoContainer {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  
  .updateTodo {
    background-color: #333;
    padding: 20px;
    text-align: center;
  }

  .updateTodo input {
    padding: 5px;
    border: none;
    border-radius: 5px;
  }
  
  .updateTodo button {
    background-color: #0077ff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin-top: 10px;
    cursor: pointer;
  }

  .updateTodo h1 {
    font-size: 24px;
    margin-bottom: 10px;
    text-decoration: underline;
    padding: 10px;
  }
  
  .updateTodo hr {
    border: 1px solid #555;
  }
  
  .updateTodo form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  